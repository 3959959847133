import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import {
  SearchBar,
  SearchResult
} from '@backstage/plugin-search-react';
import {
  Content,
  Page
} from '@backstage/core-components';
import {List} from "@material-ui/core";
import { CustomHeader } from '@internal/backstage-plugin-common-react';
import { SearchResultListItemExtension } from '@internal/backstage-plugin-confluentkafka';
import {ZuploConstants} from '@internal/backstage-plugin-zuplo-common';
import { RBAC, useAuth, SEARCH } from "@internal/backstage-plugin-common-react";


const SearchPage = () => {

  const cmodAccess  = useAuth(RBAC.SUB_SECTION_NAME.DATA, RBAC.PAGE_NAME.DATA_CMOD)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.DATA_CMOD);
  const adminApiAccess = useAuth(RBAC.SUB_SECTION_NAME.API, RBAC.PAGE_NAME.API_ADMIN)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.API_ADMIN);
  const boardingApiAccess = useAuth(RBAC.SUB_SECTION_NAME.API, RBAC.PAGE_NAME.API_BOARDING)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.API_BOARDING);
  const complianceApiAccess = useAuth(RBAC.SUB_SECTION_NAME.API, RBAC.PAGE_NAME.API_COMPLIANCE)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.API_COMPLIANCE);
  const escrowApiAccess = useAuth(RBAC.SUB_SECTION_NAME.API, RBAC.PAGE_NAME.API_ESCROW)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.API_ESCROW);
  const loanApiAccess = useAuth(RBAC.SUB_SECTION_NAME.API, RBAC.PAGE_NAME.API_LOANS)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.API_LOANS);
  const paymentsApiAccess = useAuth(RBAC.SUB_SECTION_NAME.API, RBAC.PAGE_NAME.API_PAYMENTS)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.API_PAYMENTS);
  const adminEventAccess  = useAuth(RBAC.SUB_SECTION_NAME.EVENTS, RBAC.PAGE_NAME.EVENT_ADMIN)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.EVENT_ADMIN);
  const escrowEventAccess  = useAuth(RBAC.SUB_SECTION_NAME.EVENTS, RBAC.PAGE_NAME.EVENT_ESCROW)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.EVENT_ESCROW);
  const loanEventAccess  = useAuth(RBAC.SUB_SECTION_NAME.EVENTS, RBAC.PAGE_NAME.EVENT_LOAN)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.EVENT_LOAN);
  const paymentsEventAccess  = useAuth(RBAC.SUB_SECTION_NAME.EVENTS, RBAC.PAGE_NAME.EVENT_PAYMENTS)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.EVENT_PAYMENTS);
  const complianceEventAccess  = useAuth(RBAC.SUB_SECTION_NAME.EVENTS, RBAC.PAGE_NAME.EVENT_COMPLIANCE)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
    && access.pageName === RBAC.PAGE_NAME.EVENT_COMPLIANCE);
  const boardingEventAccess  = useAuth(RBAC.SUB_SECTION_NAME.EVENTS, RBAC.PAGE_NAME.EVENT_BOARDING)?.authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER
  && access.pageName === RBAC.PAGE_NAME.EVENT_BOARDING);
  
  return (
    <Page themeId="home">
    <CustomHeader title="Search" />
    <Content>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Paper>
            <SearchBar />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <SearchResult>
          {({ results }) => (
        <List>
          {results.map(({ type, document}) => {
            switch (type) {
              case SEARCH.DATA_SEARCH:
                if(cmodAccess) {
                  return (
                  <SearchResultListItemExtension
                    result={document}
                  />
                );
                }
                break;
                case SEARCH.API_SEARCH:
                  if((adminApiAccess && document?.subType === ZuploConstants.ADMIN_KEYWORD) ||
                    (boardingApiAccess && document?.subType === ZuploConstants.BOARDING_KEYWORD) ||
                    (complianceApiAccess && document?.subType === ZuploConstants.COMPLIANCE_KEYWORD) ||
                    (escrowApiAccess && document?.subType === ZuploConstants.ESCROW_KEYWORD) ||
                    (loanApiAccess && document?.subType === ZuploConstants.LOANS_KEYWORD) ||
                    (paymentsApiAccess && document?.subType === ZuploConstants.PAYMENTS_KEYWORD)
                   )
                    {
                    return (
                    <SearchResultListItemExtension
                      result={document}
                    />
                  );
                  }
                  break;
                case SEARCH.KAFKA_SEARCH:
                  if((adminEventAccess && document?.tags === ZuploConstants.ADMIN_TITLE) ||
                    (boardingEventAccess && document?.tags === ZuploConstants.BOARDING_TITLE) ||
                    (complianceEventAccess && document?.tags === ZuploConstants.COMPLIANCE_TITLE) ||
                    (escrowEventAccess && document?.tags === ZuploConstants.ESCROW_TITLE) ||
                    (loanEventAccess && document?.tags === ZuploConstants.LOANS_TITLE) ||
                    (paymentsEventAccess && document?.tags === ZuploConstants.PAYMENTS_TITLE)
                   )
                    {
                    return (
                    <SearchResultListItemExtension
                      result={document}
                    />
                  );
                  }
                  break;
              default:
                return (
                  <SearchResultListItemExtension
                    result={document}
                  />
                );
            }
          })}
        </List>
      )}
          </SearchResult>
        </Grid>
      </Grid>
    </Content>
  </Page>
  );
};

export const searchPage = <SearchPage />;
