import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Subsections } from "../type";
import { userCurrentRolePermissionState } from "../atom";

export const useAuth = (subSectionName: string, pageName: string) => {

    const permissions = useRecoilValue(userCurrentRolePermissionState);
    const [authenticatedPermissions, setAuthenticatedPermissions] = useState<Partial<Subsections>>({});
    
    useEffect(() => {
      if (permissions) {
        const matchedPermission = permissions.find(
          rmp => rmp.subSectionName === subSectionName &&
          rmp.pages.some(page => page.pageName === pageName));
        if (matchedPermission) {
          setAuthenticatedPermissions(matchedPermission);
        }
      }
    }, [pageName, permissions, subSectionName]);
    return { authenticatedPermissions };
}
