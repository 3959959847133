import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const dataAssetsPlugin = createPlugin({
  id: 'data-assets',
  routes: {
    root: rootRouteRef,
  },
});

export const DataAssetsPage = dataAssetsPlugin.provide(
  createRoutableExtension({
    name: 'DataAssetsPage',
    component: () =>
      import('./components/DataAssets').then(m => m.DataAssestComponet),
    mountPoint: rootRouteRef,
  }),
);
export const AssetsTableDetailsPage = dataAssetsPlugin.provide(
  createRoutableExtension({
    name: 'AssetsTableDetailsPage',
    component: () =>
      import('./components/AssetsTableDetails').then(m => m.AssetsTableDetails),
    mountPoint: rootRouteRef,
  }),
);

export const LineageAssetsTableDetailsPage = dataAssetsPlugin.provide(
  createRoutableExtension({
    name: 'LineageAssetsTableDetailsPage',
    component: () =>
      import('./components/LineageAssetsTableDetails').then(m => m.LineageAssetsTableDetails),
    mountPoint: rootRouteRef,
  }),
);

export const AssetsTableListingPage = dataAssetsPlugin.provide(
  createRoutableExtension({
    name: 'AssetsTableListingPage',
    component: () =>
      import('./components/AssetsTableListing').then(m => m.AssetsTableListing),
    mountPoint: rootRouteRef,
  }),
);
export const DaraCoreTableDetails = dataAssetsPlugin.provide(
  createRoutableExtension({
    name: 'DaraCoreTableDetails',
    component: () =>
      import('./components/DaraCore').then(m => m.DaraCoreTableDetails),
    mountPoint: rootRouteRef,
  }),
);
export const ReleaseNotes = dataAssetsPlugin.provide(
  createRoutableExtension({
    name: 'ReleaseNotes',
    component: () =>
      import('./components/ReleaseNotes').then(m => m.ReleaseNotes),
    mountPoint: rootRouteRef,
  }),
);
export const DataLineageSagentHomePage = dataAssetsPlugin.provide(
  createRoutableExtension({
    name: 'DataLineageSagentHomePage',
    component: () => import('./components/DataLineageSagent/DataLineageSagentHomePage').then(m => m.DataLineageSagentHomePage),
    mountPoint: rootRouteRef,
  }),
);
export const DataLineageExternalHomePage = dataAssetsPlugin.provide(
  createRoutableExtension({
    name: 'DataLineageExternalHomePage',
    component: () => import('./components/DataLineageExternal/DataLineageExternalHomePage').then(m => m.DataLineageExternalHomePage),
    mountPoint: rootRouteRef,
  }),
);
