export const RBAC = {
    SUB_SECTION_NAME: {
        EVENTS: 'Events',
        DATA: 'Data',
        API: 'API'
    },
    PERMISSION_TYPE: {
        OPERATOR: 'Operator',
        VIEWER: 'Viewer',
        NO_ACCESS: 'No Access'
    },
    PAGE_NAME: {
        // API
        API_ADMIN: 'API Admin',
        API_BOARDING: "API Boarding",
        API_LOANS: "API Loans",
        API_LOANS_DEFAULTS: 'API Loans Default',
        API_AI_DOCS: "API AI Docs",
        API_INVESTOR_ADMIN: "API Investor Admin",
        API_AUTHORIZATION: "API Authorization",
        API_ESCROW: 'API Escrow',
        API_COMPLIANCE: 'API Compliance',
        API_PAYMENTS: 'API Payments',
        
        // EVENT
        EVENTS_TAB: "Events",
        EVENT_ADMIN: 'Admin',
        EVENT_ESCROW: 'Escrow',
        EVENT_LOAN: 'Loans',
        EVENT_PAYMENTS: 'Payments',
        EVENT_COMPLIANCE: 'Compliance',
        EVENT_BOARDING: 'Boarding',
        
        // Data
        DATA_TAB: 'Data',
        DATA_SG_CORE: 'sg_core',
        DATA_CMOD: 'cmod',
        DATA_CIRRUS: 'cirrus',
        DATA_CLAIMS: 'claims',
        DATA_ATLAS: 'atlas',
        DATA_CARE: 'care',
        LINEAGE_INTERNAL: 'Lineage - Internal',
        LINEAGE_EXTERNAL: 'Lineage - External'
    }
}