export class ZuploConstants {
    /* RBAC constants */
    static readonly API_PAYMENTS = "API-Payments";
    static readonly API_COMPLIANCE = "API-Compliance";
    static readonly API_ESCROW = "API-Escrow";
    static readonly API_LOANS = "API-Loans";
    static readonly API_BOARDING = "API-Boarding";
    static readonly API_ADMIN = "API-Admin";
    static readonly API_LOANS_DEFAULT = "API-LoanDefault";
    static readonly API_AI_DOCS = "API-AiDocs";
    static readonly API_INVESTOR_ADMIN = "API-InvestorAdmin";
    static readonly API_AUTHORIZATION = "API-Authorization";
    
    /* Payments constants */
    static readonly PAYMENTS_ID = "1"
    static readonly PAYMENTS_DESCRIPTION = "The Payments API";
    static readonly PAYMENTS_TITLE = "Payments";
    static readonly PAYMENTS_KEYWORD = "payments";

    /* Compliance constants */
    static readonly COMPLIANCE_ID = "2";
    static readonly COMPLIANCE_DESCRIPTION = "The Compliance API";
    static readonly COMPLIANCE_TITLE = "Compliance";
    static readonly COMPLIANCE_KEYWORD = "compliance";

    /* Escrow constants */
    static readonly ESCROW_ID = "3";
    static readonly ESCROW_DESCRIPTION = "The Escrow API";
    static readonly ESCROW_TITLE = "Escrow";
    static readonly ESCROW_KEYWORD = "escrow";

    /* Loans constants */
    static readonly LOANS_ID = "4";
    static readonly LOANS_DESCRIPTION = "The Loans API";
    static readonly LOANS_TITLE = "Loans";
    static readonly LOANS_KEYWORD = "loans";

    /* Boarding constants */
    static readonly BOARDING_ID = "5";
    static readonly BOARDING_DESCRIPTION = "The Boarding API";
    static readonly BOARDING_TITLE = "Boarding";
    static readonly BOARDING_KEYWORD = "boarding";

    /* Admin constants */
    static readonly ADMIN_ID = "6";
    static readonly ADMIN_DESCRIPTION = "The Admin API";
    static readonly ADMIN_TITLE = "Admin";
    static readonly ADMIN_KEYWORD = "admin";

    /* AI docs constants */
    static readonly AI_DOCS_ID = "7"
    static readonly AI_DOCS_DESCRIPTION = "The AI Docs API";
    static readonly AI_DOCS_TITLE = "AI Docs";
    static readonly AI_DOCS_KEYWORD = "docs";

    /* Loan default constants */
    static readonly LOANS_DEFAULT_ID = "8"
    static readonly LOANS_DEFAULT_DESCRIPTION = "The Loan Default API";
    static readonly LOANS_DEFAULT_TITLE = "Loans Default";
    static readonly LOANS_DEFAULT_KEYWORD = "loan-default";

    /* Investor constants */
    static readonly INVESTOR_ADMIN_ID = "9"
    static readonly INVESTOR_ADMIN_DESCRIPTION = "The Investor API";
    static readonly INVESTOR_ADMIN_TITLE = "Investor";
    static readonly INVESTOR_ADMIN_KEYWORD = "investor-admin";

    /* Authorization constants */
    static readonly AUTHORIZATION_ID = "10"
    static readonly AUTHORIZATION_DESCRIPTION = "The Authorization API";
    static readonly AUTHORIZATION_TITLE = "Authorization";
    static readonly AUTHORIZATION_KEYWORD = "authorization";

    /* Common constants */
    static readonly SERVER = "https://gateway-dv-b0f8680.zuplo.app";
    static readonly VERSION = "v1";
}

export const apiParams = {
    Z_API_KEY: "z-api-key",
    TENANT_ID: "X-TenantId",
    CUSTOMER_ID: "X-CustomerId",
    CLIENT_ID: "X-ClientId",
    PLATFORM_ID: "X-PlatformId",
    BRAND_ID: "X-BrandId",
    USER_ID: "X-UserId"
}