import { SidebarItem, SidebarScrollWrapper } from "@backstage/core-components"
import KafkaIcon from "../Root/KafkaIcon";
import StorageIcon from '@material-ui/icons/Storage';
import ExtensionIcon from '@material-ui/icons/Extension';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import React from "react"
import { RBAC, useAuth } from "@internal/backstage-plugin-common-react";
import TimelineIcon from '@material-ui/icons/Timeline';

export const SideBarItems = () => {

    const dataTabAccess = useAuth(RBAC.SUB_SECTION_NAME.DATA, RBAC.PAGE_NAME.DATA_TAB).authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER 
        && access.pageName === RBAC.PAGE_NAME.DATA_TAB);
    const eventsAccess = useAuth(RBAC.SUB_SECTION_NAME.EVENTS, RBAC.PAGE_NAME.EVENTS_TAB).authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER 
        && access.pageName === RBAC.PAGE_NAME.EVENTS_TAB);
    const lineageSagentAccess = useAuth(RBAC.SUB_SECTION_NAME.DATA, RBAC.PAGE_NAME.LINEAGE_INTERNAL).authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER 
        && access.pageName === RBAC.PAGE_NAME.LINEAGE_INTERNAL);
    const lineageExternalAccess = useAuth(RBAC.SUB_SECTION_NAME.DATA, RBAC.PAGE_NAME.LINEAGE_EXTERNAL).authenticatedPermissions.pages?.some(access => access.permissionType === RBAC.PERMISSION_TYPE.VIEWER 
        && access.pageName === RBAC.PAGE_NAME.LINEAGE_EXTERNAL);
    return (<>
        <SidebarItem icon={SearchIcon} to="/search" text="Search" />
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Documentation" />
        <SidebarItem icon={ExtensionIcon} to="zuplo" text="APIs" />
        {eventsAccess && <SidebarItem icon={KafkaIcon} to="confluentkafka" text="Events" />}
        {dataTabAccess && <SidebarItem icon={StorageIcon} to="dataassets" text="Data" />}
        <SidebarItem icon={NoteAddIcon} to="releaseNotes" text="Release Notes" />
        {lineageSagentAccess && <SidebarItem icon={TimelineIcon} to="lineageSagent" text="Lineage-Sagent" />}
        {lineageExternalAccess && <SidebarItem icon={TimelineIcon} to="lineageExternal" text="Lineage" />}
        <SidebarScrollWrapper />
    </>)
}